<script>
    /**
     * name：模板
     * user：sa0ChunLuyu
     * date：2022-03-24 11:04:36
     */
    import ltree from '../../../components/sa0/ltree/ltree.vue'

    export default {
        props: ['closeTpl', 'createReport'],
        components: {ltree},
        data() {
            return {
                visible: true,
                table_show: false,
                tpl_list: [],
                choose_id: 0,
                options: {
                    container: 'luckysheet',
                    lang: 'zh',
                    title: '',
                    showinfobar: false,
                    showtoolbar: false,
                    column: 50,
                    row: 50,
                    showstatisticBar: false,
                    enableAddRow: false,
                    enableAddBackTop: false,
                    userInfo: false,
                    cellRightClickConfig: {
                        copy: false, // 复制
                        copyAs: false, // 复制为
                        paste: false, // 粘贴
                        insertRow: false, // 插入行
                        insertColumn: false, // 插入列
                        deleteRow: false, // 删除选中行
                        deleteColumn: false, // 删除选中列
                        deleteCell: false, // 删除单元格
                        hideRow: false, // 隐藏选中行和显示选中行
                        hideColumn: false, // 隐藏选中列和显示选中列
                        rowHeight: false, // 行高
                        columnWidth: false, // 列宽
                        clear: false, // 清除内容
                        matrix: false, // 矩阵操作选区
                        sort: false, // 排序选区
                        filter: false, // 筛选选区
                        chart: false, // 图表生成
                        image: false, // 插入图片
                        link: false, // 插入链接
                        data: false, // 数据验证
                        cellFormat: false // 设置单元格格式
                    },
                    showsheetbar: false,
                    sheetFormulaBar: false
                },
            }
        },
        mounted() {
            this.getTplList()
        },
        methods: {
            getTplList() {
                // 检测报告：检测报告模板列表
                this.$sa0.post({
                    url: this.$api('检测报告：检测报告模板列表'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tpl_list = response.data.list.map((item) => {
                                return {
                                    id: item.id,
                                    type: 1,
                                    name: item.name,
                                }
                            })
                            if (this.tpl_list.length) {
                                this.tplItemClick(this.tpl_list[0].id)
                                this.$refs['ltreeref'].active_arr = [this.tpl_list[0].id]
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            treeCallback(e) {
                this.tplItemClick(e[0].id)
            },
            onClose() {
                this.closeTpl()
            },
            tplItemClick(id) {
                this.choose_id = id
                this.$sa0.post({
                    url: this.$api('检测报告：获取模板结构'), data: {
                        id: id
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            let table = response.data.table
                            this.drawTpl(table ? {sheets: [JSON.parse(table)]} : false);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            drawTpl(exportJson = false) {
                this.table_show = false;
                setTimeout(() => {
                    this.table_show = true;

                    setTimeout(() => {
                        let options = this.options
                        if (exportJson) {
                            options.data = exportJson.sheets
                        }
                        luckysheet.destroy();
                        luckysheet.create(options);
                    })
                })
            },
            createClick() {
                if (this.choose_id) {
                    this.createReport(this.choose_id)
                } else {
                    this.closeTpl()
                }

                // this.choose_id
            }
        }
    }
</script>
<template>
    <div>
        <a-drawer width="100%" title="选择模板" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-space>
                    <div class="tpl_list_wrapper">
                        <ltree ref="ltreeref" w="200px" ot="only" :dt="0" :it="1" :data="tpl_list" all="none" :all_menu="[]" :menu="[]" :callback="treeCallback"></ltree>
                    </div>
                    <div class="lce_wrapper">
                        <div v-if="table_show" class="luckysheet_wrapper">
                            <div class="t_cover"></div>
                            <div id="luckysheet" class="luckysheet_wrapper"></div>
                        </div>
                        <div>
                            <a-button @click="createClick()" type="primary">确定</a-button>
                        </div>
                    </div>
                </a-space>
            </div>
        </a-drawer>
    </div>
</template>
<style>
    .tpl_list_wrapper .ltree_wrapper{
        top: 0px !important;
        }
</style>
<style scoped>
    .t_cover{
        position: absolute;
        top: 0;
        bottom: 36px;
        left: 0;
        right: 12px;
        background: #000;
        z-index: 999999;
        opacity: 0;
        }

    .luckysheet_wrapper{
        position: relative;
        vertical-align: top;
        display: inline-block;
        width: 900px;
        height: calc(100vh - 140px);
        border-left: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        }

    .tpl_list_wrapper{
        position: relative;
        width: 200px;
        height: calc(100vh - 120px);
        }
</style>
