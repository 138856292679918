<template>
  <div class="baogaomain">
    <div class="sidebar">
      <div class="sidebar-title">委托单列表</div>
      <div class="sidebarmain">
        <div class="sidelist" v-for="(item,key) in Taskauditcomdata" :key="key"
             :class="[active_key === key ? 'active':'']" @click="clickItemJump(item,key)">
          {{ item.name }}
        </div>
      </div>

    </div>
    <div class="baogao-right">
      <div>
        <a-form-model layout="inline">
          <a-form-model-item label="">
            <a-input v-model="formInline.keywords" placeholder="请输入样品编号/名称" style="width: 200px"
                     @keyup="Get_examining_reportbianzhi()"></a-input>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-select v-model="formInline.state" style="width: 200px" @change="Get_examining_reportbianzhi()">
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="0">待编制</a-select-option>
              <a-select-option value="1">编制完成-等待提交校验</a-select-option>
              <a-select-option value="2">校验已提交-等待校验</a-select-option>
              <a-select-option value="3">校验通过-待审核</a-select-option>
              <a-select-option value="4">校验不通过</a-select-option>
              <a-select-option value="5">审核完成</a-select-option>
              <a-select-option value="6">审核不通过</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-button type="danger" @click="Clear()" class="">清空搜索</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="baogao-right-main">
        <a-space style="margin-top: 10px;" class="btner">

          <div class="btnerlist " @click="bianzhibaogaoclick()">
            <i class="iconfont icon-bianzhi vm" style="margin-top: -2px; font-size: 20px"></i> 编制报告
          </div>

          <!--                    <div class="btnerlist" @click="tijiaoclick(2)">-->
          <!--                        <i class="iconfont icon-jieshou1 " style="font-size: 24px"></i> 提交校验-->
          <!--                    </div>-->

          <div class="btnerlist" @click="tijiaoshowclick(2)">
            <i class="iconfont icon-jieshou1 " style="font-size: 24px"></i> 提交校验
          </div>

          <div class="btnerlist" @click="tijiaoclick(1)">
            <i class="iconfont icon-jurassic_last" style="font-size: 16px"></i> 撤销校验
          </div>
          <div class="btnerlist"><i class="iconfont icon-jurassic_last" style="font-size: 16px"></i> 退回编制
          </div>

          <div class="btnerlist" @click="showDrawer1">
            <i class="iconfont icon-rizhi1" style="font-size: 16px"></i> 操作日志
          </div>
          <div class="btnerlist" v-if="" @click="urlJump()">
            <i class="iconfont icon-rizhi1" style="font-size: 16px"></i> 查看报告
          </div>
        </a-space>
        <div>
          <table class="layui-table" lay-size="sm" style="margin-top: -11px">
            <thead>
            <tr>
              <th>
                <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
              </th>

              <th @click="paixuclickup(1)" style="cursor: pointer; ">
                <span>样品编号</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 1 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 1 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>
              <th @click="paixuclickup(2)" style="cursor: pointer; ">
                <span>样品名称</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 2 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 2 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>
              <th @click="paixuclickup(3)" style="cursor: pointer; ">
                <span>报告编号</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 3 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 3 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>

              <th>检验项目</th>
              <th>报告状态</th>
              <th>编制人</th>
              <th>编制时间</th>
              <th>最后修改时间</th>
              <!--                            <th>操作</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,key) in Sampledata" :key="key"
                :class="[has_id===item.sample_id ?'error_line':'', item.check ? 'active1':'']" @click="clickrow(key)">
              <td>
                <a-checkbox v-model="item.check" @change="itemActiveChange"></a-checkbox>
              </td>
              <td>{{ item.in_number }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.task_num }}</td>
              <td style="color: #40a9ff">{{ item.jc_item_count }} 项</td>
              <td class="zhuangtaispan">
                <span v-if="item.tuihui === 1" style="color: #ff0000"> {{ item.state_turn }}</span>
                <span v-else>{{ item.state_turn }}</span>
                <span style="color: #ff0000"
                      v-if="item.state_turn==='待编制-校验不通过' ||item.state_turn=== '审核不通过'">(原因：{{
                    item.remark
                  }})</span>
              </td>
              <td>{{ item.danda_name }}</td>
              <td>{{ item.create_time }}</td>
              <td>{{ item.update_time }}</td>
              <!--                            <td>-->
              <!--                                <span v-if="item.state_turn !=='待编制'"><a-button type="link" @click="urlJump(item.pdf_url)">查看报告</a-button></span>-->
              <!--                            </td>-->
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Tpl v-if="tpl_show" :closeTpl="closeTpl" :createReport="createReport"></Tpl>
    <!--历史弹窗-->
    <div v-if="edit_info1">
      <Historylist :edit_time="edit_time1" :edit_info="edit_info1" :close="onCloseDrawer1"
                   :visible="drawer_visible1"></Historylist>
    </div>

    <a-modal v-model:visible="choose_jiaoyan" title="确认校验人" @ok="tijiaoclick(2)">
      <div>
        <table class="layui-table" lay-size="sm" style="margin-top: -11px">
          <thead>
          <tr>
            <th>样品编号</th>
            <th>样品名称</th>
            <th>校验人</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,key) in choose_info_list" :key="key">
            <td>{{ item.in_number }}</td>
            <td>{{ item.name }}</td>
            <td>
              <a-select v-model="item.jiaoyan_id" class="w-full">
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(jyi,jyk) in jiaoyanren_list" :value="jyi.id">
                  {{ jyi.name }}
                </a-select-option>
              </a-select>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Tpl from './tpl.vue';
import Historylist from './lishilist/lishilist.vue';

export default {
  name: "baogaobianzhi",
  components: {Tpl, Historylist},
  data() {
    return {
      jiaoyanren_list: [],
      choose_info_list: [],
      choose_jiaoyan: false,

      tpl_show: false,
      active_id: 0,
      formInline: {
        name: '',
        status: ''
      },
      Taskauditcomdata: [],
      active_key: -1,
      active_item: false,
      Sampledata: [],
      all_check: false,
      has_id: 0,
      examining_report_id: 0,
      page_show: false,
      drawer_visible1: false,
      edit_info1: 'false',
      edit_time1: 0,
      data_name: 0,
      data_sort: 'asc',
    }
  },
  watch: {
    $route: {
      handler() {
        this.active_id = Number(this.$route.query.id ? Number(this.$route.query.id) : 0)
        this.mountedDo()//w获取公告分页列表
      },
      deep: true
    },
    itemActiveChange() {
      let do_ = true
      this.Sampledata.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.all_check = do_
    },
    allChange(e) {
      this.Sampledata.map((item, key) => {
        this.$set(this.Sampledata[key], 'check', this.all_check)
      })
    },
  },
  mounted() {
    this.active_id = Number(this.$route.query.id ? Number(this.$route.query.id) : 0)
    this.mountedDo()
  },
  methods: {
    getJiaoyanrenList() {
      this.$sa0.post({
        url: this.$api('任务:校验人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jiaoyanren_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    Clear() {
      this.formInline.keywords = '';
      this.formInline.state = '';
      this.Get_examining_reportbianzhi();
    },
    apicallback(data) {
      if (data.action === 'create') {
        this.tpl_show = true
      }
      if (data.action === 'jump') {
        this.$router.push('/jcbg/table/' + data.id)
      }
      if (data.action === 'error') {
        layer.msg(data.msg)
        this.has_id = data.id
      }
    },
    createReport(id) {
      this.tpl_show = false
      let task_id = this.active_item.id;
      let s_id = []
      this.Sampledata.map((item) => {
        if (item.check) {
          s_id.push(item.sample_id)
        }
      })
      if (s_id.length) {
        // 检测报告：在操作之前确认状态
        this.$sa0.post({
          url: this.$api('检测报告：创建检测报告'),
          data: {
            task_id: task_id,
            s_ids: s_id,
            tpl_id: id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.apicallback(response.data)
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        layer.msg('请选择样品')
      }
    },
    closeTpl() {
      this.tpl_show = false
    },
    mountedDo() {
      this.Get_task_auditcom() //委托单列表
      this.getJiaoyanrenList() //校验人列表
    },
    clickrow(key) {
      this.$set(this.Sampledata[key], 'check', !this.Sampledata[key].check)
      this.itemActiveChange()
    },

    itemActiveChange() {
      let do_ = true
      this.Sampledata.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.all_check = do_
    },
    allChange(e) {
      this.Sampledata.map((item, key) => {
        this.$set(this.Sampledata[key], 'check', this.all_check)
      })
    },
    urlJump() {
      let id = 0
      let count = 0
      let info = 0
      this.Sampledata.map((item, key) => {
        if (item.check) {
          id = item.sample_id
          count++
          info = item
        }
      })
      console.log(id)
      if (!id) return layer.msg('请选择检验报告')
      if (count !== 1) return layer.msg('只能选择一个报告进行查看')
      if (info.state_turn === "待编制") {
        layer.msg("还没有编制报告，请先编制报告")
      } else
        window.open(info.pdf_url,)
    },
    Get_task_auditcom(click = 0) {
      this.$sa0.post({
        url: this.$api('Get_task_auditcom'),
        data: {},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Taskauditcomdata = response.data.task;
            // 页面加载进来默认选中第一条
            if (!this.active_id) {
              if (this.Taskauditcomdata.length) {
                this.clickitem(this.Taskauditcomdata[click], click)
              }
            } else {
              this.Taskauditcomdata.map((item, key) => {
                if (item.id === this.active_id) {
                  this.clickitem(this.Taskauditcomdata[key], key)
                }
              })
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    clickItemJump(item) {
      this.$router.push({
        name: this.$route.name,
        query: {id: item.id}
      })
    },
    clickitem(item, key) {
      this.active_key = key;
      this.active_item = item;
      this.Get_examining_reportbianzhi()
    },
    Get_examining_reportbianzhi() {
      this.$sa0.post({
        url: this.$api('Get_examining_reportbianzhi'),
        data: {
          task_id: this.active_item.id,
          keywords: this.formInline.keywords,
          state: this.formInline.state,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Sampledata = response.data.task_check;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    paixuclickup(ordertype) {
      if (this.data_name !== ordertype) {
        this.data_name = ordertype
        this.data_sort = 'asc'
      } else {
        if (this.data_sort === 'asc') {
          this.data_sort = 'desc'
        } else {
          this.data_sort = 'asc'
        }
      }
      this.$sa0.post({
        url: this.$api('Get_examining_reportbianzhi'),
        data: {
          task_id: this.active_item.id,
          keywords: this.formInline.keywords,
          state: this.formInline.state,
          data_name: this.data_name,
          data_sort: this.data_sort,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Sampledata = response.data.task_check;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    // 编制报告
    bianzhibaogaoclick() {
      let task_id = this.active_item.id;
      let s_id = []
      this.Sampledata.map((item) => {
        if (item.check) {
          s_id.push(item.sample_id)
        }
      })
      if (s_id.length) {
        // 检测报告：在操作之前确认状态
        this.$sa0.post({
          url: this.$api('检测报告：在操作之前确认状态'),
          data: {
            task_id: task_id,
            s_ids: s_id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.apicallback(response.data)

              // this.Sampledata = response.data.task_check;
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })

        // this.$router.push('/jcbg/table/' + task_id + '?ids=' + s_id.join())
      } else {
        layer.msg('请选择样品')
      }
    },

    // 提交校验
    tijiaoshowclick() {
      this.choose_info_list = []
      this.Sampledata.map((item) => {
        if (item.check) {
          this.choose_info_list.push(item)
        }
      })
      this.choose_jiaoyan = true
    },
    tijiaoclick(inumber) {
      let examining_report_ids = []
      let jiaoyan_id_ids = []
      let ok_ = true
      this.Sampledata.map((item) => {
        if (item.check) {
          examining_report_ids.push(Number(item.examining_report_id))
          jiaoyan_id_ids.push(Number(item.jiaoyan_id))
          if (!Number(item.jiaoyan_id)) ok_ = false
        }
      })
      if (!ok_) return layer.msg('请选择校验人')
      this.$sa0.post({
        url: this.$api('Set_examining_report_all1'),
        data: {
          state: inumber,
          // examining_report_id:  this.examining_report_id,
          examining_report_id: examining_report_ids.join(','),
          jiaoyan_id_ids: jiaoyan_id_ids.join(','),
        },


      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg(response.message)
            this.choose_jiaoyan = false
            this.Get_examining_reportbianzhi()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    // 日志定义 关闭抽屉时的 函数
    onCloseDrawer1() {
      this.drawer_visible1 = false;
      this.Get_examining_reportbianzhi()
    },
    // 日志定义 打开抽屉时的 函数
    showDrawer1() {
      let id = 0
      let count = 0
      this.Sampledata.map((item, key) => {
        if (item.check) {
          this.edit_info1 = item;
          id = item.examining_report_id
          count++
        }
      })
      console.log(9999, this.edit_info1)
      if (!id) return layer.msg('请选择报告')
      if (count !== 1) return layer.msg('只能选择一个报告')

      // this.edit_info2 = false
      setTimeout(() => {
        this.edit_time1 = new Date() / 1;
        this.drawer_visible1 = true;
        // this.edit_info2 = true
      })
    }

  }
}
</script>

<style scoped>
.error_line {
  background: #ffe9e9;
}

.baogaomain {
  width: 100%;
  display: flex;
}

.sidebar {
  width: 250px;
  background: #fdfdfd;
  height: calc(100vh - 120px);
  border: 1px solid #f9f9f9;
  display: flex;
  flex-direction: column;
}

.baogao-right {
  flex: 1;
  margin-left: 20px
}

.sidelist {
  width: 100%;
  line-height: 30px;
  border-bottom: 1px solid #eeeeee;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidelist:hover {
  background: #eff8ff;
  color: #333333;
}


.btner {
  width: 100%;
  background: #f9f9f9;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
}

.active {
  background: #96cbf9;
}

.active1 {
  background: #ebf6ff;
}

.btnerlist {
  cursor: pointer;
  border-right: 1px solid #eeeeee;
  height: 26px;
  line-height: 26px;
  padding: 0 10px
}

.btnerlist :last-child(1) {
  border: none
}

.fcadd {
  color: #66c14c !important;
  font-size: 18px !important;
}

.btnerlist i {
  color: #999999;
  font-size: 14px;
  vertical-align: middle
}

.btnerlist:hover {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;

}

.btnerlist:hover i, .btnerlist:hover.fcadd {
  color: #ffffff !important
}

.isthis {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;
}

.isthis i {
  color: #ffffff
}

.baogao-right-main {
  width: 100%;
  padding-top: 0px;
  margin-top: 0px
}

.zhuangtaispan span {
  display: block
}

table tr {
  cursor: pointer
}

.sidebar-title {
  width: 100%;
  padding: 10px 5px;
  font-weight: bold;
  border-bottom: 1px solid #eeeeee;
  font-size: 14px;
  background: #f9f9f9
}

.sidebarmain {
  width: 100%;
  flex: 1;
  overflow-y: auto
}

.sidebarmain::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.sidebarmain::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #cccccc;
}

.sidebarmain::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #f9f9f9;
}

.ant-table-column-sorter {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 5px;
  cursor: pointer;
  width: 10px;
  vertical-align: middle;
}

.jiantou {
  color: #999999
}

.classname {
  color: #1890ff;
}

.fl {
  float: left
}

.active {
  background: #ebf6ff
}

table tr {
  cursor: pointer
}


</style>
